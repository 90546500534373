export const Base_url="https://app.skinmind.ch/api/"
export const createDoctor="v2/createDoctor.php"
export const loginDoctor="v2/loginDoctor.php"
export const loginAdmin="v2/loginAdmin.php"
export const getMyPatients="v2/getMyPatients.php"
export const getProfile="v2/getProfile.php"
export const getPatientBodySpots="v2/getPatientBodySpots.php"
export const getSpotImagesv2="v2/getSpotImages.php"
export const getSpotImagesv3="v3/getSpotImages.php"
export const getUserData="v2/getUserData.php"
export const getSpotDataComments="v2/getSpotDataComments.php"
export const getSicknesses="v2/getSicknesses.php"
export const getAllPatients="v2/getAllPatients.php"
export const getAllDoctor="v2/getAllDoctor.php"
export const insertSpotByDocter="v3/insertSpotByDocter.php"
export const deleteSpotByDocter="v2/deleteSpotByDocter.php"
export const insertSpotDataByDocter="v3/insertSpotDataByDocter.php"
export const addCommentApi="v2/addComment.php"
export const deleteDoctor="v2/deleteDoctor.php"
export const setDescription="v2/setDescription.php"
export const uploadPhoto = "v1/uploadFile.php";
export const getSpotDataAnalyze="v3/getSpotDataAnalyze.php"
export const getSpotMessages="v3/getSpotMessages.php"
export const sendMessage="v3/sendMessage.php"

